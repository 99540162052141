import MainMiddleware from "@/middlewares/main-middleware";
import { Infrastructure } from "@/infrastructure";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { redirect } from "@/middlewares/auth-middleware";

const Infra = new Infrastructure();

export default async function RedirectMiddleware(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  MainMiddleware();
  const code = to.query.code as string;
  const error = to.query.error as string;
  const errorDescription = to.query.error_description as string;
  const state = to.query.state as string;
  const roomId = state.split(/[+ ]/)[0] || "";
  const noRedirect = !!state.split(/[+ ]/)[1];

  if (code) {
    try {
      await Infra.authByCode(code);
      if (roomId) {
        next({
          name: "meet",
          params: { pathMatch: roomId }
        });
      } else {
        next({
          name: "index"
        });
      }
    } catch (e: any) {
      next({
        name: "index",
        query: {
          errorMessage:
            "Ошибка авторизации" +
            (e?.message ? ": " + e.message : JSON.stringify(e)),
          redirect: "true",
          meet: roomId || undefined
        }
      });
    }
  } else if (error) {
    if (error === "request_unauthorized") {
      if (noRedirect) {
        if (roomId) {
          next({
            name: "meet",
            params: { pathMatch: roomId },
            query: {
              auth: "no-auth"
            }
          });
        } else {
          next({
            name: "index"
          });
        }
      } else {
        void redirect(roomId);
      }
      return;
    }
    next({
      name: "index",
      query: {
        errorMessage:
          "Ошибка авторизации: " +
          error +
          (errorDescription ? ": " + errorDescription : ""),
        redirect: "true",
        meet: roomId || undefined
      }
    });
  } else {
    next({
      name: "index"
    });
  }
}
