import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { Infrastructure } from "@/infrastructure";

const Infra = new Infrastructure();

export const redirect = async (
  roomId?: string,
  clearLocalStorage?: boolean,
  onlyFT?: boolean
): Promise<void> => {
  return new Promise((_, reject) => {
    Infra.getConfig()
      .then(({ redirectURI }) => {
        redirectURI =
          (!localStorage.getItem("tn-meet__access-token") &&
            !location.search.includes("error=request_unauthorized")) ||
          onlyFT
            ? redirectURI.replace(/\/authorize\/?\?/, "/authorize_ft?")
            : redirectURI;
        if (process.env.NODE_ENV === "development") {
          const match = redirectURI.match(/redirect_uri=(.*?)&/);
          if (match && match.length) {
            redirectURI = redirectURI.replace(
              match[0],
              `redirect_uri=${location.origin}/redirect&`
            );
          }
        }

        if (roomId) {
          redirectURI = redirectURI.replace(/&state=.*&?/, "&");
          if (redirectURI.endsWith("&")) {
            redirectURI = redirectURI.replace(/&$/, "");
          }
          redirectURI += `&state=${roomId}${onlyFT ? "+no-redirect" : ""}`;
        }

        if (clearLocalStorage) {
          localStorage.clear();
        }

        location.assign(redirectURI);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export default async function AuthMiddleware(
  to: RouteLocationNormalized,
  _,
  next: NavigationGuardNext,
  useNext: boolean = true
) {
  const roomId = to.path.split("/").pop();
  try {
    // const token: string | null = localStorage.getItem("tn-meet__access-token");

    // if (!token) {
    //   localStorage.clear();
    //   await redirect(roomId);
    // }
    if (useNext) {
      next();
    }
  } catch (e: any) {
    next({
      name: "index",
      query: {
        errorMessage:
          "Ошибка входа во встречу: " +
          (e?.message ? e.message : JSON.stringify(e)),
        meet: roomId
      }
    });
  }
}
